<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>消息中心</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.keywords" placeholder="输入消息标题" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="消息类型" filterable clearable>
                        <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.isRead" placeholder="选择状态" filterable clearable>
                        <Option v-for="item in List" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a v-if="permissionButton.readOnly && (row.type === '消息通知' || row.type === '系统消息')"
                           @click="handleView(row, index)">详情</a>
                        <a v-if="permissionButton.view && row.type === '审批待办'" @click="handleMessage(row, index)">处理</a>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <view-message v-model="showViewModel" :messageRecipientId="messageRecipientId" :isRead="isRead" :id="id" v-on:getMessageList="getMessageList"></view-message>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqUserMessagePage} from "@/api/admin-api";
import viewMessage from './view'

export default {
    name: 'messageList',
    components: {
        viewMessage
    },
    data() {
        return {
            id: '',
            isRead: '',
            messageRecipientId: '',
            showViewModel: false,
            filter: {
                keywords: '',
                status: null,
                type: null,
                isRead: null,
                isProcessed: null
            },
            List: [
                {
                    id: 0,
                    name: '未读',
                },
                {
                    id: 1,
                    name: '已读',
                }
            ],
            typeList: [
                {
                    id: '系统消息',
                    name: '系统消息',
                },
                {
                    id: '消息通知',
                    name: '消息通知',
                }
            ],
            loading: false,
            dataList: [],
            permissionButton: {
                readOnly: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    width: 100,
                    align: 'center',
                },
                {
                    title: '消息类型',
                    key: 'type',
                    width: 150,
                },
                {
                    title: '标题',
                    key: 'title',
                    width: 200,
                },
                {
                    title: '内容',
                    key: 'content',
                    tooltip: 'ture',
                    minWidth: 400,
                },
                {
                    title: '状态',
                    width: 80,
                    render: (h, params) => {
                        let temp = '';
                        if (params.row.isRead === 0) {
                            temp = '未读';
                        }
                        if (params.row.isRead === 1) {
                            temp = '已读';
                        }
                        return h('span', temp);
                    }
                },
                {
                    title: '发送人',
                    key: 'senderName',
                    width: 120,
                },
                {
                    title: '发送时间',
                    key: 'sendTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 100,
                    align: 'center'
                },
            ],
        }
    },
    methods: {
        getMessageList() {
            this.loading = true;
            this.dataList = [];

            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                isRead: this.filter.isRead,
                type: this.filter.type,
            };
            reqUserMessagePage(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.keywords= '';
            this.filter.status= null;
            this.filter.type= null;
            this.filter.isRead= null;
            this.filter.isProcessed= null;
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                isRead: this.filter.isRead,
                type: this.filter.type,
            };
            reqUserMessagePage(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleMessage(row, index) {

        },
        handleView(row, index) {
            this.id = row.id
            this.isRead = row.isRead
            this.messageRecipientId = row.messageRecipientId
            this.showViewModel = true
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getMessageList();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getMessageList();
        },
        doSearching() {
            this.pageNum = 1;
            this.getMessageList();
        }
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton);
        this.getMessageList();
    }
}
</script>
