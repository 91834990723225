<template>
    <Modal v-model="showForm" title="消息详情" :closable="false" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" label-colon :label-width="90">
            <FormItem label="接收人">{{ viewForm.targetName }}</FormItem>
            <FormItem label="标题">{{ viewForm.title }}</FormItem>
            <FormItem label="内容">{{ viewForm.content }}</FormItem>
            <FormItem label="类型">{{ viewForm.type }}</FormItem>
            <FormItem label="发送人">{{ viewForm.senderName }}</FormItem>
            <FormItem label="发送时间">{{ viewForm.sendTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqEditStatus, reqGetMessageById} from "@/api/admin-api";

export default {
    name: "viewMessage",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
        isRead: '',
        messageRecipientId: '',
    },
    data() {
        return {
            showForm: this.value,
            viewForm: {},
        }
    },
    methods: {
        getMessageById() {
            reqGetMessageById({id: this.id}).then((res) => {
                this.viewForm = res.data.data;
            }).catch(() => {
                this.viewForm = {}
            })
        },
        closeModal(val) {
            if (this.isRead === 0){
                this.viewForm.messageRecipientId = this.messageRecipientId
                reqEditStatus(this.viewForm).then((res)=>{
                    if (res.data.code === 1){
                        this.$emit('input', val);
                        this.$emit('getMessageList');
                    }
                }).catch(()=>{
                    this.$emit('input', val);
                    this.$emit('getMessageList');
                })
            }else {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getMessageById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
